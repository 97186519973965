// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { Container, Row, Col, Collapse, Input } from 'reactstrap';
// import Config from '../../../../helpers/Config';
// import { replaceWhiteSpacesWithDashSymbolInUrl, makeAnyStringLengthShort } from '../../../../helpers/ConversionHelper';
// import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen,replaceLoclizationLabel } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';

// const ChangeMenuCategory1 = () => {
//     const [CategoriesList, setCategoriesList] = useState([]);
//     const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');

//     useEffect(() => {
//         const fetchCategories = async () => {
//             const headers = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const param = {
//                 requestParameters: {
//                     PageNo: 1,
//                     PageSize: 100,
//                     recordValueJson: "[]",
//                 },
//             };

//             const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
//             if (response != null && response.data != null) {
//                 setCategoriesList(JSON.parse(response.data.data));
//             }

//             const lnCode = getLanguageCodeFromSession();
//             setLangCode(lnCode);

//             const localizationData = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null);
//             if (localizationData != null && localizationData.length > 0) {
//                 setLocalizationLabelsArray(localizationData);
//             }
//         };

//         fetchCategories().catch(console.error);
//     }, []);

//     return (
//         <Container>
//             <Row>


//                     <ul>
//                         {CategoriesList.map((item, idx) => (
//                             <li key={idx}>
//                                 <div>
//                                     <h5>{makeAnyStringLengthShort(item.Name,30 )}</h5>
//                                     {CategoriesList.filter(obj => obj.ParentCategoryID === item.CategoryID).map((subItem, subIdx) => (
//                                         <div key={subIdx}>
//                                             <label htmlFor={`category_${subIdx}`}>
//                                                 {makeAnyStringLengthShort(subItem.Name, 30)}
//                                             </label>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </li>
//                         ))}
//                     </ul>
//             </Row>
//         </Container>
//     );
// };

// export default ChangeMenuCategory1;



// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Collapse, Input } from 'reactstrap';
// import Config from '../../../../helpers/Config';
// import { replaceWhiteSpacesWithDashSymbolInUrl, makeAnyStringLengthShort } from '../../../../helpers/ConversionHelper';
// import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';

// const ChangeMenuCategory1 = () => {
//     const [CategoriesList, setCategoriesList] = useState([]);
//     const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');

//     useEffect(() => {
//         const fetchCategories = async () => {
//             const headers = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const param = {
//                 requestParameters: {
//                     PageNo: 1,
//                     PageSize: 100,
//                     recordValueJson: "[]",
//                 },
//             };

//             const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
//             if (response != null && response.data != null) {
//                 setCategoriesList(JSON.parse(response.data.data));
//             }

//             const lnCode = getLanguageCodeFromSession();
//             setLangCode(lnCode);

//             const localizationData = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null);
//             if (localizationData != null && localizationData.length > 0) {
//                 setLocalizationLabelsArray(localizationData);
//             }
//         };

//         fetchCategories().catch(console.error);
//     }, []);

//     const renderSubcategories = (parentCategoryID) => {
//         return CategoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID).map((subItem, subIdx) => (
//             <option key={subIdx} value={subItem.CategoryID}>
//                 {makeAnyStringLengthShort(subItem.Name, 30)}
//             </option>
//         ));
//     };

//     const renderCategories = () => {
//         return CategoriesList.filter(item => !item.ParentCategoryID).map((item, idx) => (
//             <div key={idx}>

//                     {makeAnyStringLengthShort(item.Name, 30)}

//                 <select id={`category_${item.CategoryID}`}>
//                     <option value="">Select an option</option>
//                     {renderSubcategories(item.CategoryID)}
//                 </select>
//                 {renderSubcategories(item.CategoryID).length > 0 && (
//                     <div style={{ marginLeft: '20px' }}>
//                         {renderSubcategories(item.CategoryID).map((subItem) => (
//                             <div key={subItem.key}>
//                                 <label htmlFor={`subcategory_${subItem.value}`}>
//                                     {makeAnyStringLengthShort(subItem.props.children, 30)}
//                                 </label>
//                                 <select id={`subcategory_${subItem.value}`}>
//                                     <option value="">Select an option</option>
//                                     {renderSubcategories(subItem.value)}
//                                 </select>
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//         ));
//     };

//     return (
//         <Container>
//             <Row>
//                 <Col>
//                     {renderCategories()}
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default ChangeMenuCategory1;




// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import Config from '../../../../helpers/Config';
// import { replaceWhiteSpacesWithDashSymbolInUrl, makeAnyStringLengthShort } from '../../../../helpers/ConversionHelper';
// import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';

// const ChangeMenuCategory1 = () => {
//     const [CategoriesList, setCategoriesList] = useState([]);
//     const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');
//     const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
//     const [subcategoryDropdownOpen, setSubcategoryDropdownOpen] = useState(false);
//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const [selectedSubcategory, setSelectedSubcategory] = useState(null);

//     useEffect(() => {
//         const fetchCategories = async () => {
//             const headers = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const param = {
//                 requestParameters: {
//                     PageNo: 1,
//                     PageSize: 100,
//                     recordValueJson: "[]",
//                 },
//             };

//             const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
//             if (response != null && response.data != null) {
//                 setCategoriesList(JSON.parse(response.data.data));
//             }

//             const lnCode = getLanguageCodeFromSession();
//             setLangCode(lnCode);

//             const localizationData = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null);
//             if (localizationData != null && localizationData.length > 0) {
//                 setLocalizationLabelsArray(localizationData);
//             }
//         };

//         fetchCategories().catch(console.error);
//     }, []);

//     const toggleCategoryDropdown = () => setCategoryDropdownOpen(prevState => !prevState);
//     const toggleSubcategoryDropdown = () => setSubcategoryDropdownOpen(prevState => !prevState);

//     const handleCategorySelect = (categoryId) => {
//         setSelectedCategory(categoryId);
//         setSelectedSubcategory(null); // Clear subcategory selection when category changes
//     };

//     const handleSubcategorySelect = (subcategoryId) => {
//         setSelectedSubcategory(subcategoryId);
//     };

//     const renderSubcategories = (parentCategoryID) => {
//         return CategoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID).map((subItem) => (
//             <DropdownItem key={subItem.CategoryID} onClick={() => handleSubcategorySelect(subItem.CategoryID)}>
//                 {makeAnyStringLengthShort(subItem.Name, 30)}
//             </DropdownItem>
//         ));
//     };

//     const renderCategories = () => {
//         return CategoriesList.filter(item => !item.ParentCategoryID).map((item) => (
//             <DropdownItem key={item.CategoryID} onClick={() => handleCategorySelect(item.CategoryID)}>
//                 {makeAnyStringLengthShort(item.Name, 30)}
//             </DropdownItem>
//         ));
//     };

//     return (
//         <Container>
//             <Row>
//                 <Col>
//                     <Dropdown isOpen={categoryDropdownOpen} toggle={toggleCategoryDropdown}>
//                         <DropdownToggle caret>
//                             {selectedCategory ? CategoriesList.find(c => c.CategoryID === selectedCategory)?.Name : "Select a Category"}
//                         </DropdownToggle>
//                         <DropdownMenu>
//                             {renderCategories()}
//                         </DropdownMenu>
//                     </Dropdown>

//                     {selectedCategory && (
//                         <Dropdown isOpen={subcategoryDropdownOpen} toggle={toggleSubcategoryDropdown}>
//                             <DropdownToggle caret>
//                                 {selectedSubcategory ? CategoriesList.find(c => c.CategoryID === selectedSubcategory)?.Name : "Select a Subcategory"}
//                             </DropdownToggle>
//                             <DropdownMenu>
//                                 {renderSubcategories(selectedCategory)}
//                             </DropdownMenu>
//                         </Dropdown>
//                     )}
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default ChangeMenuCategory1;

// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
// import Config from '../../../../helpers/Config';
// import { makeAnyStringLengthShort } from '../../../../helpers/ConversionHelper';
// import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';

// const ChangeMenuCategory1 = () => {
//     const [CategoriesList, setCategoriesList] = useState([]);
//     const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');
//     const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
//     const [subcategoryDropdownOpen, setSubcategoryDropdownOpen] = useState(false);
//     const [selectedCategory, setSelectedCategory] = useState(null);
//     const [selectedSubcategory, setSelectedSubcategory] = useState(null);
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const headers = {
//                     Accept: 'application/json',
//                     'Content-Type': 'application/json',
//                 };

//                 const param = {
//                     requestParameters: {
//                         PageNo: 1,
//                         PageSize: 100,
//                         recordValueJson: "[]",
//                     },
//                 };

//                 const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
//                 if (response?.data) {
//                     setCategoriesList(JSON.parse(response.data.data));
//                 }

//                 const lnCode = getLanguageCodeFromSession();
//                 setLangCode(lnCode);

//                 const localizationData = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null);
//                 if (localizationData?.length > 0) {
//                     setLocalizationLabelsArray(localizationData);
//                 }
//             } catch (error) {
//                 console.error('Error fetching categories:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchCategories();
//     }, []);

//     const toggleCategoryDropdown = () => setCategoryDropdownOpen(prevState => !prevState);
//     const toggleSubcategoryDropdown = () => setSubcategoryDropdownOpen(prevState => !prevState);

//     const handleCategorySelect = (categoryId) => {
//         setSelectedCategory(categoryId);
//         setSelectedSubcategory(null); // Clear subcategory selection when category changes
//         toggleCategoryDropdown(); // Close the category dropdown after selection
//     };

//     const handleSubcategorySelect = (subcategoryId) => {
//         setSelectedSubcategory(subcategoryId);
//         toggleSubcategoryDropdown(); // Close the subcategory dropdown after selection
//     };

//     const renderSubcategories = (parentCategoryID) => {
//         return CategoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID).map((subItem) => (
//             <DropdownItem key={subItem.CategoryID} onClick={() => handleSubcategorySelect(subItem.CategoryID)}>
//                 {makeAnyStringLengthShort(subItem.Name, 30)}
//             </DropdownItem>
//         ));
//     };

//     const renderCategories = () => {
//         return CategoriesList.filter(item => !item.ParentCategoryID).map((item) => (
//             <DropdownItem key={item.CategoryID} onClick={() => handleCategorySelect(item.CategoryID)}>
//                 {makeAnyStringLengthShort(item.Name, 30)}
//             </DropdownItem>
//         ));
//     };

//     if (loading) {
//         return (
//             <Container className="text-center">
//                 <Row>
//                     <Col>
//                         <Spinner color="primary" />
//                     </Col>
//                 </Row>
//             </Container>
//         );
//     }

//     return (
//         <Container>
//             <Row>
//                 <Col>
//                     <div className="d-flex flex-column align-items-start">
//                         <Dropdown isOpen={categoryDropdownOpen} toggle={toggleCategoryDropdown} className="mb-3">
//                             <DropdownToggle caret color="primary">
//                                 {selectedCategory ? CategoriesList.find(c => c.CategoryID === selectedCategory)?.Name : "Select a Category"}
//                             </DropdownToggle>
//                             <DropdownMenu>
//                                 {renderCategories()}
//                             </DropdownMenu>
//                         </Dropdown>

//                         {selectedCategory && (
//                             <Dropdown isOpen={subcategoryDropdownOpen} toggle={toggleSubcategoryDropdown}>
//                                 <DropdownToggle caret color="secondary">
//                                     {selectedSubcategory ? CategoriesList.find(c => c.CategoryID === selectedSubcategory)?.Name : "Select a Subcategory"}
//                                 </DropdownToggle>
//                                 <DropdownMenu>
//                                     {renderSubcategories(selectedCategory)}
//                                     {CategoriesList.filter(subItem => subItem.ParentCategoryID === selectedCategory).length === 0 && (
//                                         <DropdownItem disabled>No subcategories available</DropdownItem>
//                                     )}
//                                 </DropdownMenu>
//                             </Dropdown>
//                         )}
//                     </div>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default ChangeMenuCategory1;




// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Dropdown, DropdownButton, DropdownItem } from 'react-bootstrap';
// import Config from '../../../../helpers/Config';
// import { makeAnyStringLengthShort } from '../../../../helpers/ConversionHelper';
// import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';
// import './ChangeMenuCategory1.css';

// const ChangeMenuCategory1 = () => {
//     const [categoriesList, setCategoriesList] = useState([]);
//     const [localizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const headers = {
//                     Accept: 'application/json',
//                     'Content-Type': 'application/json',
//                 };

//                 const param = {
//                     requestParameters: {
//                         PageNo: 1,
//                         PageSize: 100,
//                         recordValueJson: "[]",
//                     },
//                 };

//                 const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true);
//                 if (response?.data?.data) {
//                     setCategoriesList(JSON.parse(response.data.data));
//                 }

//                 const lnCode = getLanguageCodeFromSession();
//                 setLangCode(lnCode);

//                 const localizationData = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null);
//                 if (localizationData?.length > 0) {
//                     setLocalizationLabelsArray(localizationData);
//                 }
//             } catch (error) {
//                 console.error(error);
//             }
//         };

//         fetchCategories();
//     }, []);

//     const getSubcategories = (parentCategoryID) => {
//         return categoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID);
//     };

//     const renderSubcategories = (parentCategoryID) => {
//         const subcategories = getSubcategories(parentCategoryID);
//         return subcategories.map(subItem => (
//             <Dropdown.Item key={subItem.CategoryID} eventKey={subItem.CategoryID}>
//                 {makeAnyStringLengthShort(subItem.Name, 30)}
//             </Dropdown.Item>
//         ));
//     };

//     const renderCategories = () => {
//         return categoriesList
//             .filter(item => !item.ParentCategoryID)
//             .map(item => (
//                 <Col key={item.id} className="d-flex align-items-center justify-content-center mb-3">
//                     <Dropdown key={item.CategoryID} className="category-dropdown">
//                         <DropdownButton id={`category_${item.CategoryID}`} title={makeAnyStringLengthShort(item.Name, 30)} className='dropdownCh'>
//                             {renderSubcategories(item.CategoryID)}
//                         </DropdownButton>
//                     </Dropdown>

//                 </Col>
//             ));
//     };

//     return (
//         <div className="container-fluid">
//             <div className="row">

//                 <div className="d-flex ">

//                     {renderCategories()}

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ChangeMenuCategory1;



// import React, { useState, useEffect } from 'react';
// import Config from '../../../../helpers/Config';
// import { makeAnyStringLengthShort, replaceWhiteSpacesWithDashSymbolInUrl } from '../../../../helpers/ConversionHelper';
// import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';
// import './ChangeMenuCategory1.css';
// import { Link, useParams } from 'react-router-dom';

// import rootAction from "../../../../stateManagment/actions/rootAction";
// import { useDispatch } from 'react-redux';

// const ChangeMenuCategory1 = (props) => {
//     const [categoriesList, setCategoriesList] = useState([]);
//     const [localizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');
//     let categParamArray = [];
//     const params = useParams();
//     categParamArray.push(parseInt(params.category_id) ?? 0);
//     const [CategoryID, setCategoryID] = useState(categParamArray);
//     const dispatch=useDispatch()
//     const [ManufacturerID, setManufacturerID] = useState([]);
//     const [ProductsList, setProductsList] = useState([]);
//     const [TotalRecords, setTotalRecords] = useState(0);


//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const headers = {
//                     Accept: 'application/json',
//                     'Content-Type': 'application/json',
//                 };

//                 const param = {
//                     requestParameters: {
//                         PageNo: 1,
//                         PageSize: 100,
//                         recordValueJson: "[]",
//                     },
//                 };

//                 const [categoriesResponse, localizationResponse] = await Promise.all([
//                     MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true),
//                     GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null)
//                 ]);

//                 if (categoriesResponse?.data?.data) {
//                     setCategoriesList(JSON.parse(categoriesResponse.data.data));
//                 }

//                 const lnCode = getLanguageCodeFromSession();
//                 setLangCode(lnCode);

//                 if (localizationResponse?.length > 0) {
//                     setLocalizationLabelsArray(localizationResponse);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchCategories();
//     }, []);


//     const setFilterValueInParent = async (e, value, type) => {

//         let categoriesIdsCommaSeperated = CategoryID.length > 0 ? CategoryID.join(",") : "";

//         if (type == "category") {

//             let updatedCategories = [...CategoryID];
//             const index = updatedCategories.indexOf(value);

//             if (index === -1) {
//                 updatedCategories.push(value);
//             } else {
//                 updatedCategories.splice(index, 1);
//             }
//             updatedCategories = updatedCategories.filter((num) => num !== 0);

//             await setCategoryID(updatedCategories);
//             categoriesIdsCommaSeperated = updatedCategories.join(",");


//         } 

//         await getAllProductsAfterAnyFilterChange(1, categoriesIdsCommaSeperated, null);
        

//     }

//     const getAllProductsAfterAnyFilterChange = async ( _categoryId, _manufacturerId,) => {

//         try {

//             dispatch(rootAction.commonAction.setLoading(true));

            


//             let paramFromPage = {
//                 requestParameters: {
                   
//                     CategoryID: _categoryId ?? CategoryID.join(","),
//                     ManufacturerID: _manufacturerId ?? ManufacturerID.join(","),
                   
//                 },
//             };



//             let responseAllProducts = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_All_PRODUCTS'], null, paramFromPage, "POST", true);
//             if (responseAllProducts != null && responseAllProducts.data != null) {

//                 await setProductsList(JSON.parse(responseAllProducts.data.data));
//                 let AllProducts = JSON.parse(responseAllProducts.data.data);
//                 await setTotalRecords(parseInt(AllProducts[0]?.TotalRecords ?? 0))
//                 console.log(JSON.parse(responseAllProducts.data.data));

             
//             }


//             setTimeout(() => {
//                 dispatch(rootAction.commonAction.setLoading(false));
//             }, 2000);


//         }
//         catch (error) {

//             setTimeout(() => {
//                 dispatch(rootAction.commonAction.setLoading(false));
//             }, 2000);

//         }



//     }
//     const forceLoadCategory = (url) => {
//         window.location.href = url;
//     }


//     const getSubcategories = (parentCategoryID) => {
//         return categoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID);
//     };

//     const renderSubcategories = (parentCategoryID) => {
//         const subcategories = getSubcategories(parentCategoryID);
//         return (
//             <ul className="dropdown">
//                 {subcategories.map(subItem => (
//                     <li key={subItem.CategoryID}>
//                         <Link to="#" onClick={(e) => {props.setFilterValueInParent(e, subItem.CategoryID, "category");}} >{makeAnyStringLengthShort(subItem.Name, 30)}</Link>
//                     </li>
//                 ))}
//             </ul>
//         );
//     };

//     const renderCategories = () => {
//         return categoriesList
//             .filter(item => !item.ParentCategoryID)
//             .map(item => (
//                 <li key={item.CategoryID}>
//                     <Link to="#" onClick={(e) => {

//                         document.body.style.overflow = "visible";
//                         forceLoadCategory(`/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)} `);
//                     }}>{makeAnyStringLengthShort(item.Name, 30)}</Link>
//                     {renderSubcategories(item.CategoryID)}
//                 </li>
//             ));
//     };

//     return (
//         <nav role="navigation" className="primary-navigation">
//             <ul>
//                 {renderCategories()}
//             </ul>
//         </nav>
//     );
// };

// export default ChangeMenuCategory1;


  

// import React, { useState, useEffect } from 'react';
// import Config from '../../../../helpers/Config';
// import { makeAnyStringLengthShort, replaceWhiteSpacesWithDashSymbolInUrl } from '../../../../helpers/ConversionHelper';
// import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from '../../../../helpers/CommonHelper';
// import GlobalEnums from '../../../../helpers/GlobalEnums';
// import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';
// import './ChangeMenuCategory1.css';
// import { Link, useParams } from 'react-router-dom';

// import rootAction from "../../../../stateManagment/actions/rootAction";
// import { useDispatch } from 'react-redux';

// const ChangeMenuCategory1 = (props) => {
//     const [categoriesList, setCategoriesList] = useState([]);
//     const [localizationLabelsArray, setLocalizationLabelsArray] = useState([]);
//     const [langCode, setLangCode] = useState('');
//     let categParamArray = [];
//     const params = useParams();
//     categParamArray.push(parseInt(params.category_id) ?? 0);
    


//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const headers = {
//                     Accept: 'application/json',
//                     'Content-Type': 'application/json',
//                 };

//                 const param = {
//                     requestParameters: {
//                         PageNo: 1,
//                         PageSize: 100,
//                         recordValueJson: "[]",
//                     },
//                 };

//                 const [categoriesResponse, localizationResponse] = await Promise.all([
//                     MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true),
//                     GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null)
//                 ]);

//                 if (categoriesResponse?.data?.data) {
//                     setCategoriesList(JSON.parse(categoriesResponse.data.data));
//                 }

//                 const lnCode = getLanguageCodeFromSession();
//                 setLangCode(lnCode);

//                 if (localizationResponse?.length > 0) {
//                     setLocalizationLabelsArray(localizationResponse);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchCategories();
//     }, []);


    
//     const forceLoadCategory = (url) => {
//         window.location.href = url;
//     }


//     const getSubcategories = (parentCategoryID) => {
//         return categoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID);
//     };

//     const renderSubcategories = (parentCategoryID) => {
//         const subcategories = getSubcategories(parentCategoryID);
//         return (
//             <ul className="dropdown">
//                 {subcategories.map(subItem => (
//                     <li key={subItem.CategoryID}>
//                         <Link to="#" onClick={(e) => { props.setFilterValueInParent(e, subItem.CategoryID, "category"); }} >{makeAnyStringLengthShort(subItem.Name, 30)}</Link>
//                     </li>
//                 ))}
//             </ul>
//         );
//     };

//     const renderCategories = () => {
//         return categoriesList
//             .filter(item => !item.ParentCategoryID)
//             .map(item => (
//                 <li key={item.CategoryID}>
//                     <Link to="#" onClick={(e) => {

//                         document.body.style.overflow = "visible";
//                         forceLoadCategory(`/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)} `);
//                     }}>{makeAnyStringLengthShort(item.Name, 20)}</Link>
//                     {renderSubcategories(item.CategoryID)}
//                 </li>
//             ));
//     };

//     return (
//         <nav role="navigation" className="primary-navigation">
//             <ul>
//                 {renderCategories()}
//             </ul>
//         </nav>
//     );
// };

// export default ChangeMenuCategory1;



import React, { useState, useEffect } from 'react';
import Config from '../../../../helpers/Config';
import { makeAnyStringLengthShort, replaceWhiteSpacesWithDashSymbolInUrl } from '../../../../helpers/ConversionHelper';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen } from '../../../../helpers/CommonHelper';
import GlobalEnums from '../../../../helpers/GlobalEnums';
import { MakeApiCallAsync } from '../../../../helpers/ApiHelpers';
import './ChangeMenuCategory1.css';
import { Link, useParams } from 'react-router-dom';
import rootAction from "../../../../stateManagment/actions/rootAction";
import { useDispatch } from 'react-redux';

const ChangeMenuCategory1 = (props) => {
    const [categoriesList, setCategoriesList] = useState([]);
    const [localizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [langCode, setLangCode] = useState('');
    let categParamArray = [];
    const params = useParams();
    categParamArray.push(parseInt(params.category_id) ?? 0);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };

                const param = {
                    requestParameters: {
                        PageNo: 1,
                        PageSize: 100,
                        recordValueJson: "[]",
                    },
                };

                const [categoriesResponse, localizationResponse] = await Promise.all([
                    MakeApiCallAsync(Config.END_POINT_NAMES['GET_CATEGORIES_LIST'], null, param, headers, "POST", true),
                    GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["CategoryListPage"], null)
                ]);

                if (categoriesResponse?.data?.data) {
                    setCategoriesList(JSON.parse(categoriesResponse.data.data));
                }

                const lnCode = getLanguageCodeFromSession();
                setLangCode(lnCode);

                if (localizationResponse?.length > 0) {
                    setLocalizationLabelsArray(localizationResponse);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCategories();
    }, []);

    const forceLoadCategory = (url) => {
        window.location.href = url;
    }

    const getSubcategories = (parentCategoryID) => {
        return categoriesList.filter(subItem => subItem.ParentCategoryID === parentCategoryID);
    };

    const renderSubcategories = (parentCategoryID) => {
        const subcategories = getSubcategories(parentCategoryID);
        return (
            <ul className="dropdown">
                {subcategories.map(subItem => (
                    <li key={subItem.CategoryID}>
                        <Link to="#" onClick={(e) => {
                        document.body.style.overflow = "visible";
                        forceLoadCategory(`/${getLanguageCodeFromSession()}/all-products/${subItem.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(subItem.Name)}`);
                    }} >{makeAnyStringLengthShort(subItem.Name, 30)}</Link>
                    </li>
                ))}
            </ul>
        );
    };

    const renderCategories = () => {
        return categoriesList
            .filter(item => !item.ParentCategoryID)
            .map(item => (
                <li key={item.CategoryID} className="category-item">
                    <Link to="#" onClick={(e) => {
                        document.body.style.overflow = "visible";
                        forceLoadCategory(`/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)}`);
                    }}>
                        {makeAnyStringLengthShort(item.Name, 30)}

                        <i className={`fas fa-chevron-down dropdown-icon`}></i> 
                    </Link>
                    {renderSubcategories(item.CategoryID)}
                </li>
            ));
    };

    // const renderCategories = () => {
    //     return categoriesList
    //         .filter(item => !item.ParentCategoryID && item.Name !== "TOOLS & HOME IMPROVEMENT")
    //         .map(item => (
    //             <li key={item.CategoryID} className="category-item">
    //                 <Link to="#" onClick={(e) => {
    //                     e.preventDefault();  // Prevent default anchor behavior
    //                     document.body.style.overflow = "visible";
    //                     forceLoadCategory(`/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)}`);
    //                 }}>
    //                     {makeAnyStringLengthShort(item.Name, 30)}
    //                     <i className={`fas fa-chevron-down dropdown-icon`}></i> 
    //                 </Link>
    //                 {renderSubcategories(item.CategoryID)}
    //             </li>
    //         ));
    // };
    

    return (
        <nav role="navigation" className="primary-navigation">
            <ul>
                {renderCategories()}
            </ul>
        </nav>
    );
};

export default ChangeMenuCategory1;


// <Dropdown key={item.CategoryID} className="category-dropdown">
//                     <DropdownButton id={`category_${item.CategoryID}`} title={makeAnyStringLengthShort(item.Name, 30)}>
//                         {renderSubcategories(item.CategoryID)}
//                     </DropdownButton>
//                 </Dropdown>