// import React, { useState, useEffect } from "react";
// import { Col, Row } from "reactstrap";
// import filterIcon2 from '../../../resources/themeContent/images/category/icon/2.png';
// import filterIcon3 from '../../../resources/themeContent/images/category/icon/3.png';
// import filterIcon4 from '../../../resources/themeContent/images/category/icon/4.png';



// const ProductsFilterOptions = (props) => {



//     return (
//         <>
//             <Row>
//                 <Col xs="12">
//                     <div className="filter-main-btn">
//                         <span
//                             className="filter-btn"
//                             onClick={(e) => {
//                                 props.setLeftSidebarOpenCloseFromFilter(e,true);
//                             }}>
//                             <i className="fa fa-filter" aria-hidden="true"></i> Filter
//                         </span>
//                     </div>
//                 </Col>
//                 <Col xs="12">
//                     <div className="product-filter-content">

//                         <div className="collection-view">
//                             <ul>
//                                 <li
//                                     onClick={() => {
//                                         props.setLayout("");
//                                         props.setGrid(props.cols);
//                                     }}>
//                                     <i className="fa fa-th grid-layout-view"></i>
//                                 </li>
//                                 <li
//                                     onClick={() => {
//                                         props.setLayout("list-view");
//                                         props.setGrid("col-lg-12");
//                                     }}>
//                                     <i className="fa fa-list-ul list-layout-view"></i>
//                                 </li>
//                             </ul>
//                         </div>
//                         <div className="collection-grid-view" style={props.layout === "list-view" ? { opacity: 0 } : { opacity: 1 }}>
//                             <ul>
//                                 <li onClick={() => props.setGrid("col-lg-6")}>
//                                     <img src={filterIcon2} alt="" className="product-2-layout-view" />
//                                 </li>
//                                 <li onClick={() => props.setGrid("col-lg-4")}>
//                                     <img src={filterIcon3} alt="" className="product-3-layout-view" />
//                                 </li>
//                                 <li onClick={() => props.setGrid("col-lg-3")}>
//                                     <img src={filterIcon4} alt="" className="product-4-layout-view" />
//                                 </li>
//                             </ul>
//                         </div>
//                         <div className="product-page-per-view">
//                             {/* <select >
//                                 <option value="10">10 Products Par Page</option>
//                                 <option value="15">15 Products Par Page</option>
//                                 <option value="20">20 Products Par Page</option>
//                             </select> */}
//                             <select onChange={(e) => props.setPageSizeFromProductFilter(e)}>
//                                 <option value="10">10 Products Par Page</option>
//                                 <option value="15">15 Products Par Page</option>
//                                 <option value="20">20 Products Par Page</option>
//                                 <option value="30">30 Products Par Page</option>
//                                 <option value="40">40 Products Par Page</option>
//                                 <option value="50">50 Products Par Page</option>
//                                 <option value="100">100 Products Par Page</option>
//                             </select>
//                         </div>
//                         <div className="product-page-filter">

//                             <select onChange={(e) => props.setSortByFilter(e)}>
//                                 <option value="">Featured</option>

//                                 <option value="Price ASC">Price Ascending</option>
//                                 <option value="Price DESC">Price Descending</option>
//                                 <option value="Date DESC">Date Ascending</option>
//                                 <option value="Date ASC">Date Descending</option>

//                             </select>
//                         </div>
//                     </div>
//                 </Col>
//             </Row>
//         </>
//     );
// };

// export default ProductsFilterOptions;





import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "reactstrap";
import filterIcon2 from '../../../resources/themeContent/images/category/icon/2.png';
import filterIcon3 from '../../../resources/themeContent/images/category/icon/3.png';
import filterIcon4 from '../../../resources/themeContent/images/category/icon/4.png';



const ProductsFilterOptions = (props) => {
    const [realView, setRealView] = useState(false); // Adding state for Real View
    const [width, setWidth] = useState(false)





    // Changes in new 

    const popoverRef = useRef(null);
    useEffect(() => {
        const popover = new window.bootstrap.Popover(popoverRef.current, {
            content: 'Reels View',


        });
        return () => {
            popover.dispose();
        };
    }, []);

    return (
        <>
            <Row>
                <Col xs="6">
                    <div className="filter-main-btn">
                        <span
                            className="filter-btn"
                            onClick={(e) => {
                                props.setLeftSidebarOpenCloseFromFilter(e, true);
                            }}>
                            <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        </span>
                    </div>
                </Col>

                {/* For Reels View in mobile screen */}


                <Col xs="6">
                    <div className="filter-main-btn" style={{ marginTop: "-10px", float: "right" }}>
                        <div
                            style={props.layout === "list-view" ? { opacity: 0 } : { opacity: 1 }}
                            ref={popoverRef}
                            data-bs-placement="top"
                            data-bs-content="Top popover"
                            data-bs-trigger="hover focus"
                        >
                            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                                <li
                                    onClick={() => {
                                        props.setGrid("col-lg-12");
                                        props.setRealView(!realView);
                                        // props.henlRealView(realView)
                                        props.setWidth(width);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img src={require("../../../resources/custom/images/reels icons.png")} width={45} alt="popover-trigger" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>


                {/* 
                <Col xs="6">
                    <div className="filter-main-btn" style={{marginTop:"-10px", float:"right"}}>

                        <div className="" style={props.layout === "list-view" ? { opacity: 0 } : { opacity: 1 }}  ref={popoverRef} data-bs-placement="top" data-bs-content="Top popover" data-bs-trigger="hover focus" >
                            <ul>
                                <li
                                    onClick={() => {
                                        props.setGrid("col-lg-12");
                                        props.setRealView(!realView);
                                        // props.henlRealView(realView)
                                        props.setWidth(width)


                                    }}
                                    style={{ cursor: "pointer", }}>
                                    <img src={require("../../../resources/custom/images/reels icons.png")} width={45} alt="popover-trigger" data-bs-placement="top" data-bs-content="Top popover"/>
                                </li>

                            </ul>
                        </div>
                    </div>


                </Col> */}

                <Col xs="12">
                    <div className="product-filter-content">

                        <div className="collection-view">
                            <ul>
                                <li
                                    onClick={() => {
                                        props.setLayout("");
                                        props.setGrid(props.cols);
                                        props.setWidth(width)
                                    }}>
                                    <i className="fa fa-th grid-layout-view"></i>
                                </li>
                                <li
                                    onClick={() => {
                                        props.setLayout("list-view");
                                        props.setGrid("col-lg-12");
                                        props.setWidth(!width)
                                    }}>
                                    <i className="fa fa-list-ul list-layout-view"></i>
                                </li>
                            </ul>
                        </div>
                        <div className="collection-grid-view" style={props.layout === "list-view" ? { opacity: 0 } : { opacity: 1 }}>
                            {/* <ul>
                                <li onClick={() => props.setGrid("col-lg-6")}>
                                    <img src={filterIcon2} alt="" className="product-2-layout-view" />
                                </li>
                                <li onClick={() => props.setGrid("col-lg-4")}>
                                    <img src={filterIcon3} alt="" className="product-3-layout-view" />
                                </li>
                                <li onClick={() => props.setGrid("col-lg-3")}>
                                    <img src={filterIcon4} alt="" className="product-4-layout-view" />
                                </li>
                                <li 
                                    onClick={() => {
                                        props.setGrid("col-lg-12");
                                        props.setRealView(!realView); 
                                        // props.henlRealView(realView)


                                    }} 
                                    style={{cursor:"pointer", textAlign:"center", marginRight:"70px"}}>
                                    <h5>Show Reels View</h5> 
                                </li>
                            </ul> */}
                            <ul>
                                <li onClick={() => {
                                    props.setGrid("col-lg-6")
                                    props.setRealView(realView)
                                    props.setWidth(width)
                                }} >
                                    <img src={filterIcon2} alt="" className="product-2-layout-view" />
                                </li>
                                <li onClick={() => {
                                    props.setGrid("col-lg-4")
                                    props.setRealView(realView)
                                    props.setWidth(width)
                                }}>
                                    <img src={filterIcon3} alt="" className="product-3-layout-view" />
                                </li>
                                <li onClick={() => {
                                    props.setGrid("col-lg-3")
                                    props.setRealView(realView)
                                    props.setWidth(width)
                                }}>
                                    <img src={filterIcon4} alt="" className="product-4-layout-view" />
                                </li>
                            </ul>
                        </div>

                        {/* Show Reals View  */}

                        <div className="collection-grid-view" style={props.layout === "list-view" ? { opacity: 0 } : { opacity: 1 }}>
                            <ul>
                                {/* <li onClick={() => props.setGrid("col-lg-12")} style={{cursor:"pointer",textAlign:"center",marginRight:"70px"}}>
                                    <h5>Show Reals View</h5>   
                                    {/* <Link to="/show-reals-page" style={{fontSize:"16px",color:"black",fontWeight:"600"}}>Show Reals View</Link>                                 
                                    
                                </li> */}
                                <li
                                    onClick={() => {
                                        props.setGrid("col-lg-12");
                                        props.setRealView(!realView);
                                        // props.henlRealView(realView)
                                        props.setWidth(width)


                                    }}
                                    style={{ cursor: "pointer", textAlign: "center", marginRight: "70px" }}>
                                    <h5>Show Reels View</h5>
                                </li>

                            </ul>
                        </div>

                        <div className="product-page-per-view">
                            {/* <select >
                                <option value="10">10 Products Par Page</option>
                                <option value="15">15 Products Par Page</option>
                                <option value="20">20 Products Par Page</option>
                            </select> */}
                            <select onChange={(e) => props.setPageSizeFromProductFilter(e)}>
                                <option value="10">10 Products Par Page</option>
                                <option value="15">15 Products Par Page</option>
                                <option value="20">20 Products Par Page</option>
                                <option value="30">30 Products Par Page</option>
                                <option value="40">40 Products Par Page</option>
                                <option value="50">50 Products Par Page</option>
                                <option value="100">100 Products Par Page</option>
                            </select>
                        </div>

                        <div className="product-page-filter">

                            <select onChange={(e) => props.setSortByFilter(e)}>
                                <option value="">Featured</option>

                                <option value="Price ASC">Price Ascending</option>
                                <option value="Price DESC">Price Descending</option>
                                <option value="Date DESC">Date Ascending</option>
                                <option value="Date ASC">Date Descending</option>

                            </select>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ProductsFilterOptions;
