// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from 'react-redux';
// import { Col, Row, Media } from "reactstrap";
// import { Link } from 'react-router-dom';
// import Config from "../../../helpers/Config";
// import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
// import rootAction from "../../../stateManagment/actions/rootAction";
// import { LOADER_DURATION } from "../../../helpers/Constants";
// import { makeAnyStringLengthShort } from "../../../helpers/ConversionHelper";
// import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";





// const CompaignSection = () => {
//     const dispatch = useDispatch();
//     const [CampaignList, setCampaignList] = useState([]);
//     const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         // declare the data fetching function
//         const getCampaignList = async () => {


//             const headersCampaign = {
//                 // customerid: userData?.UserID,
//                 // customeremail: userData.EmailAddress,
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',

//             }


//             const paramCampaign = {
//                 requestParameters: {
//                     recordValueJson: "[]",
//                 },
//             };

//             const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEB_CAMPAIGN_LIST'], null, paramCampaign, headersCampaign, "POST", true);
//             if (response != null && response.data != null) {
//                 await setCampaignList(JSON.parse(response.data.data));
//                 console.log(JSON.parse(response.data.data));
//             }


//         }

//         //--start loader
//         dispatch(rootAction.commonAction.setLoading(true));

//         // call the function
//         getCampaignList().catch(console.error);

//         //--stop loader
//         setTimeout(() => {
//             dispatch(rootAction.commonAction.setLoading(false));
//         }, LOADER_DURATION);

//     }, [])


//     return (
//         <section className="collection-banner section-py-space">
//             <div className="container-fluid">
//                 <Row className="collection2">
//                     {CampaignList && CampaignList?.slice(0,6)?.map((item, i) => (

//                         <Col md="4" key={i}>
//                             <div className="collection-banner-main banner-1 p-left mb-2">
//                                 <div className="collection-img">
//                                     <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img " alt="banner" />
//                                 </div>
//                                 <div className="collection-banner-contain ">
//                                     <div>
//                                         <h3>{ makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>
//                                         <h4>{ makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
//                                         <div className="shop">
//                                             <Link
//                                                to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
//                                                >
//                                                View Detail
//                                             </Link>


//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                     ))}
//                 </Row>
//             </div>
//         </section>
//     );
// };

// export default CompaignSection;


// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from 'react-redux';
// import { Col, Row, Media } from "reactstrap";
// import { Link } from 'react-router-dom';
// import Config from "../../../helpers/Config";
// import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
// import rootAction from "../../../stateManagment/actions/rootAction";
// import { LOADER_DURATION } from "../../../helpers/Constants";
// import { makeAnyStringLengthShort } from "../../../helpers/ConversionHelper";
// import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";

// const CompaignSection = () => {
//     const dispatch = useDispatch();
//     const [CampaignList, setCampaignList] = useState([]);
//     const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         // declare the data fetching function
//         const getCampaignList = async () => {
//             const headersCampaign = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const paramCampaign = {
//                 requestParameters: {
//                     recordValueJson: "[]",
//                 },
//             };

//             const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEB_CAMPAIGN_LIST'], null, paramCampaign, headersCampaign, "POST", true);
//             if (response != null && response.data != null) {
//                 setCampaignList(JSON.parse(response.data.data));
//             }
//         };

//         //--start loader
//         dispatch(rootAction.commonAction.setLoading(true));

//         // call the function
//         getCampaignList().catch(console.error);

//         //--stop loader
//         setTimeout(() => {
//             dispatch(rootAction.commonAction.setLoading(false));
//         }, LOADER_DURATION);

//     }, [dispatch]);

//     // Separate campaigns into 'Festival' and 'Others'
//     const festivalCampaigns = CampaignList.find(item => item.MainTitle === 'Festival');

//     const otherCampaigns = CampaignList.filter(item => item.MainTitle !== 'Festival');

//     return (
//         <section className="collection-banner section-py-space">
//             <div className="container-fluid">
//                 {/* Row for 'Festival' campaigns */}
//                 {festivalCampaigns.length > 0 && (
//                     <Row className="collection2">
//                         {festivalCampaigns.slice(0, 6).map((item, i) => (
//                             <Col  md="4" sm="6" key={i}>
//                                 <div className="collection-banner-main banner-1 p-left mb-2">
//                                     <div className="collection-img">
//                                         <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
//                                     </div>
//                                     <div className="collection-banner-contain">
//                                         <div>
//                                             <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>
//                                             <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
//                                             <div className="shop">
//                                                 <Link
//                                                     to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
//                                                 >
//                                                     View Detail
//                                                 </Link>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Col>
//                         ))}
//                     </Row>
//                 )}

//                 {/* Row for other campaigns */}
//                 {otherCampaigns.length > 0 && (
//                     <Row className="collection2">
//                         {otherCampaigns.slice(0, 6).map((item, i) => (
//                             <Col  md="4" sm="6" key={i}>
//                                 <div className="collection-banner-main banner-1 p-left mb-2">
//                                     <div className="collection-img">
//                                         <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
//                                     </div>
//                                     <div className="collection-banner-contain">
//                                         <div>
//                                             <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>
//                                             <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
//                                             <div className="shop">
//                                                 <Link
//                                                     to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
//                                                 >
//                                                     View Detail
//                                                 </Link>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Col>
//                         ))}
//                     </Row>
//                 )}
//             </div>
//         </section>
//     );
// };

// export default CompaignSection;


// import React, { useEffect, useState } from "react";
// import { useDispatch } from 'react-redux';
// import { Col, Row, Media } from "reactstrap";
// import { Link } from 'react-router-dom';
// import Config from "../../../helpers/Config";
// import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
// import rootAction from "../../../stateManagment/actions/rootAction";
// import { LOADER_DURATION } from "../../../helpers/Constants";
// import { makeAnyStringLengthShort } from "../../../helpers/ConversionHelper";
// import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";

// const CompaignSection = () => {
//     const dispatch = useDispatch();
//     const [CampaignList, setCampaignList] = useState([]);
//     const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

//     useEffect(() => {
//         // declare the data fetching function
//         const getCampaignList = async () => {
//             const headersCampaign = {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             };

//             const paramCampaign = {
//                 requestParameters: {
//                     recordValueJson: "[]",
//                 },
//             };

//             const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEB_CAMPAIGN_LIST'], null, paramCampaign, headersCampaign, "POST", true);
//             if (response != null && response.data != null) {
//                 setCampaignList(JSON.parse(response.data.data));
//             }
//         };

//         //--start loader
//         dispatch(rootAction.commonAction.setLoading(true));

//         // call the function
//         getCampaignList().catch(console.error);

//         //--stop loader
//         setTimeout(() => {
//             dispatch(rootAction.commonAction.setLoading(false));
//         }, LOADER_DURATION);

//     }, [dispatch]);

//     // Function to calculate remaining time in days, hours, minutes, and seconds
//     const calculateRemainingTime = (endDate) => {
//         const end = new Date(endDate);
//         const now = new Date();
//         const differenceInTime = end.getTime() - now.getTime();

//         if (differenceInTime <= 0) return 'Campaign has ended';

//         const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
//         const differenceInHours = Math.floor((differenceInTime % (1000 * 3600 * 24)) / (1000 * 3600));
//         const differenceInMinutes = Math.floor((differenceInTime % (1000 * 3600)) / (1000 * 60));
//         const differenceInSeconds = Math.floor((differenceInTime % (1000 * 60)) / 1000);

//         return { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds };
//     };

//     // Hook to update countdown every second
//     const [remainingTimes, setRemainingTimes] = useState({});

//     useEffect(() => {
//         const updateCountdowns = () => {
//             const newRemainingTimes = {};
//             CampaignList.forEach(item => {
//                 if (item.DisplayEndDate) {
//                     newRemainingTimes[item.CampaignId] = calculateRemainingTime(item.DisplayEndDate);
//                 }
//             });
//             setRemainingTimes(newRemainingTimes);
//         };

//         // Initial call to set remaining times
//         updateCountdowns();

//         // Update countdown every second
//         const intervalId = setInterval(updateCountdowns, 1000);

//         return () => clearInterval(intervalId); // Cleanup on component unmount
//     }, [CampaignList]);

//     // Separate campaigns into those including 'Festival' and others
//     const festivalCampaigns = CampaignList.filter(item => item.MainTitle.includes('Festival'));
//     const otherCampaigns = CampaignList.filter(item => !item.MainTitle.includes('Festival'));

//     return (
//         <section className="collection-banner section-py-space">
//             <div className="container-fluid">
//                 {/* Row for campaigns including 'Festival' */}
//                 <Row className="collection2">
//                     {festivalCampaigns.length > 0 && (
//                         <>
//                             {festivalCampaigns.slice(0, 6).map((item, i) => (
//                                 <Col md="4" sm="6" key={i}>
//                                     <div className="collection-banner-main banner-1 p-left mb-2">
//                                         <div className="collection-img">
//                                             <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
//                                         </div>

//                                         <div className="collection-banner-contain">

//                                             <div>
//                                                 <div className="d-flex" style={{ justifyContent: "space-between" }}>

//                                                     <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>

//                                                 </div>
//                                                 <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
//                                                 {/* <p className="campaign-date">
//                                                 {item.DisplayStartDate ? `Start Date: ${new Date(item.DisplayStartDate).toLocaleDateString()}` : 'No start date available'}
//                                             </p>
//                                             <p className="campaign-date">
//                                                 {item.DisplayEndDate ? `End Date: ${new Date(item.DisplayEndDate).toLocaleDateString()}` : 'No end date available'}
//                                             </p> */}
//                                                 <p className="campaign-duration text-light">
//                                                     {remainingTimes[item.CampaignId] ?
//                                                         `Remaining Time: ${remainingTimes[item.CampaignId].differenceInDays} days ${remainingTimes[item.CampaignId].differenceInHours} hours ${remainingTimes[item.CampaignId].differenceInMinutes} minutes ${remainingTimes[item.CampaignId].differenceInSeconds} seconds`
//                                                         : 'Duration information not available'}
//                                                 </p>
//                                                 <div className="shop">
//                                                     <Link
//                                                         to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
//                                                     >
//                                                         View Detail
//                                                     </Link>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Col>
//                             ))}
//                         </>
//                     )}

//                     {/* Row for other campaigns */}
//                     {otherCampaigns.length > 0 && (
//                         <>
//                             {otherCampaigns.slice(0, 6).map((item, i) => (
//                                 <Col md="4" sm="6" key={i}>
//                                     <div className="collection-banner-main banner-1 p-left mb-2">
//                                         <div className="collection-img">
//                                             <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
//                                         </div>
//                                         <div className="collection-banner-contain">
//                                             <div>
//                                                 <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>
//                                                 <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
//                                                 <div className="shop">
//                                                     <Link
//                                                         to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
//                                                     >
//                                                         View Detail
//                                                     </Link>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Col>
//                             ))}
//                         </>

//                     )}
//                 </Row>
//             </div>
//         </section>
//     );
// };

// export default CompaignSection;




import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Col, Row, Media } from "reactstrap";
import { Link } from 'react-router-dom';
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { makeAnyStringLengthShort } from "../../../helpers/ConversionHelper";
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";
import "./CompaignSection.css"

const CompaignSection = () => {
    const dispatch = useDispatch();
    const [CampaignList, setCampaignList] = useState([]);
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);

    useEffect(() => {
        const getCampaignList = async () => {
            const headersCampaign = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            const paramCampaign = {
                requestParameters: {
                    recordValueJson: "[]",
                },
            };

            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEB_CAMPAIGN_LIST'], null, paramCampaign, headersCampaign, "POST", true);
            if (response != null && response.data != null) {
                setCampaignList(JSON.parse(response.data.data));
            }
        };

        dispatch(rootAction.commonAction.setLoading(true));
        getCampaignList().catch(console.error);
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);

    }, [dispatch]);

    const calculateRemainingTime = (endDate) => {
        const end = new Date(endDate);
        const now = new Date();
        const differenceInTime = end.getTime() - now.getTime();

        if (differenceInTime <= 0) return 'Campaign has ended';

        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        const differenceInHours = Math.floor((differenceInTime % (1000 * 3600 * 24)) / (1000 * 3600));
        const differenceInMinutes = Math.floor((differenceInTime % (1000 * 3600)) / (1000 * 60));
        const differenceInSeconds = Math.floor((differenceInTime % (1000 * 60)) / 1000);

        return { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds };
    };

    const [remainingTimes, setRemainingTimes] = useState({});

    useEffect(() => {
        const updateCountdowns = () => {
            const newRemainingTimes = {};
            CampaignList.forEach(item => {
                if (item.DisplayEndDate) {
                    newRemainingTimes[item.CampaignId] = calculateRemainingTime(item.DisplayEndDate);
                }
            });
            setRemainingTimes(newRemainingTimes);
        };

        updateCountdowns();
        const intervalId = setInterval(updateCountdowns, 1000);

        return () => clearInterval(intervalId);
    }, [CampaignList]);

    const festivalCampaigns = CampaignList.filter(item => item.MainTitle.includes('Festival'));
    const DoorLockCampaigns = CampaignList.filter(item => item.MainTitle.includes('Door Lock'));
    const otherCampaigns = CampaignList.filter(item => !item.MainTitle.includes('Festival'));
    const otherCampaigns1 = otherCampaigns.filter(item => !item.MainTitle.includes('Door Lock'));

    const combinedCampaigns = [...festivalCampaigns, ...otherCampaigns, ...otherCampaigns, ...otherCampaigns1]; // Combine all campaigns

    return (
        <section className="collection-banner section-py-space">
            <div className="container-fluid">
                {/* <Row className="collection2">
                    {combinedCampaigns.slice(0, 12).map((item, i) => (
                        <Col md="4" sm="6" key={i}>
                            <div className="collection-banner-main banner-1 p-left mb-2">
                                <div className="collection-img">
                                    <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
                                    <div className="countdown-timer">
                                        {remainingTimes[item.CampaignId] ?
                                            `${remainingTimes[item.CampaignId].differenceInDays} days ${remainingTimes[item.CampaignId].differenceInHours} hours ${remainingTimes[item.CampaignId].differenceInMinutes} minutes ${remainingTimes[item.CampaignId].differenceInSeconds} seconds`
                                            : 'Duration information not available'}
                                    </div>
                                </div>
                                <div className="collection-banner-contain">
                                    <div>
                                        <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>
                                        <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
                                        <div className="shop">
                                            <Link
                                                to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
                                            >
                                                View Detail
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row> */}
                <Row className="collection2">
                    {festivalCampaigns.length > 0 && (
                        <>
                            {festivalCampaigns.slice(0, 6).map((item, i) => (
                                <Col md="4" sm="6" key={i}>
                                    <div className="collection-banner-main banner-1 p-left mb-2">
                                        <div className="collection-img">
                                            <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
                                            {/* <div className="countdown-timer">
                                                {remainingTimes[item.CampaignId] ?
                                                    `${remainingTimes[item.CampaignId].differenceInDays} days ${remainingTimes[item.CampaignId].differenceInHours} hours ${remainingTimes[item.CampaignId].differenceInMinutes} minutes ${remainingTimes[item.CampaignId].differenceInSeconds} seconds`
                                                    : 'Duration information not available'}
                                            </div> */}
                                        </div>

                                        <div className="collection-banner-contain">

                                            <div>
                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>

                                                    <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>

                                                </div>
                                                <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
                                                {/* <p className="campaign-date">
                                                {item.DisplayStartDate ? `Start Date: ${new Date(item.DisplayStartDate).toLocaleDateString()}` : 'No start date available'}
                                            </p>
                                            <p className="campaign-date">
                                                {item.DisplayEndDate ? `End Date: ${new Date(item.DisplayEndDate).toLocaleDateString()}` : 'No end date available'}
                                            </p> */}
                                                {/* <p className="campaign-duration text-light">
                                                    {remainingTimes[item.CampaignId] ?
                                                        `Remaining Time: ${remainingTimes[item.CampaignId].differenceInDays} days ${remainingTimes[item.CampaignId].differenceInHours} hours ${remainingTimes[item.CampaignId].differenceInMinutes} minutes ${remainingTimes[item.CampaignId].differenceInSeconds} seconds`
                                                        : 'Duration information not available'}
                                                </p> */}
                                                <div className="countdown-timer">
                                                    {remainingTimes[item.CampaignId] ?
                                                        `${remainingTimes[item.CampaignId].differenceInDays} Days ${remainingTimes[item.CampaignId].differenceInHours} Hours ${remainingTimes[item.CampaignId].differenceInMinutes} minutes ${remainingTimes[item.CampaignId].differenceInSeconds} Seconds`
                                                        : 'Duration information not available'}
                                                </div>
                                                <div className="shop">
                                                    <Link
                                                        to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
                                                    >
                                                        View Detail
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </>
                    )}

                    {DoorLockCampaigns.length > 0 && (
                        <>
                            {DoorLockCampaigns.slice(0, 6).map((item, i) => (
                                <Col md="4" sm="6" key={i}>
                                    <div className="collection-banner-main banner-1 p-left mb-2">
                                        <div className="collection-img">
                                            <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />

                                        </div>

                                        <div className="collection-banner-contain">

                                            <div>
                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>

                                                    <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>

                                                </div>
                                                <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>

                                                <div className="linkToAmazon">
                                                    <Link
                                                        className="text-white"
                                                        to='https://www.amazon.com/stores/page/06A2BB03-FCBA-4DAA-BF23-162A8DFE6233?ingress=2&visitId=f8a8e5b4-55fa-4deb-a6a9-2852126eaf8c&store_ref=bl_ast_dp_brandLogo_sto&ref_=ast_bln'
                                                    >
                                                        Show more Product on Amazon »»
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </>
                    )}



                    {/* Row for other campaigns */}
                    {otherCampaigns1.length > 0 && (
                        <>
                            {otherCampaigns1.slice(0, 6).map((item, i) => (
                                <Col md="4" sm="6" key={i}>
                                    <div className="collection-banner-main banner-1 p-left mb-2">
                                        <div className="collection-img">
                                            <Media src={adminPanelBaseURL + item.CoverPictureUrl} className="img-fluid bg-img" alt="banner" />
                                        </div>
                                        <div className="collection-banner-contain">
                                            <div>
                                                <h3>{makeAnyStringLengthShort(item.DiscountTitle, 25)}</h3>
                                                <h4>{makeAnyStringLengthShort(item.MainTitle, 35)}</h4>
                                                <div className="shop">
                                                    <Link
                                                        to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}
                                                    >
                                                        View Detail
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </>

                    )}
                </Row>
            </div>
        </section>
    );
};

export default CompaignSection;
