import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About-Us" parent="Home"/>

      <section className="about-page section-big-py-space">
        <div className="custom-container">
          <Row>
            <Col lg="6">
              <div className="banner-section">
                <Media src={aboutImg} className="img-fluid w-100" alt="" />
              </div>
            </Col>
            <Col lg="6">
              <h4>About our Store</h4>
              <p className="mb-2">
                {" "}
                Welcome to <b> Parijat Handicraft</b> - Elevate Your Surroundings with Artistic Grace!              </p>
              <p className="mb-2">
                {" "}
                At Parijat, we invite you to immerse yourself in a world of exquisite handicrafts that embody the perfect blend of tradition and contemporary elegance. Discover our curated collection of handcrafted treasures, where each piece is a testament to the skill and passion of our artisans. From intricately designed home decor to intricately woven textiles, every creation at Parijat tells a unique story.
              </p>
              <p className="mb-2">
                {" "}
               
                Just like the delicate Parijat flower that blooms in the moonlight, our handicrafts exude natural beauty and timeless charm. Explore a range of products that echo the rhythms of nature, bringing a touch of tranquility and sophistication to your surroundings.
              </p>
              <p>
                {" "}
               <b> Parijat Handicraft </b> seamlessly merges the age-old artistry of traditional craftsmanship with the modern touch of innovation. Our online platform offers you a user-friendly and immersive experience, ensuring that your journey through our collections is as delightful as the pieces themselv
              </p>
            </Col>
          </Row>
        </div>
      </section>

      <CustomerTestimonial />

    </>
  );

}

export default About;
