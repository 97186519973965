// import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
// import { Link, useNavigate } from 'react-router-dom';
// import { useRouter } from "next/router";
// import { useSelector, useDispatch } from 'react-redux';
// import { AddCustomerWishList, AddProductToCart } from '../../../helpers/CartHelper';
// import Img from "../../../helpers/utils/BgImgRatio";
// import myImage from '../../../resources/custom/images/product_main_2.jpg';
// import { makeProductShortDescription, replaceWhiteSpacesWithDashSymbolInUrl } from "../../../helpers/ConversionHelper";
// import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
// import Config from "../../../helpers/Config";
// import ProductRatingStars from "./ProductRatingStars";
// import rootAction from "../../../stateManagment/actions/rootAction";
// import { debounce } from "lodash";
// import { showErrorMsg } from "../../../helpers/ValidationHelper";
// import { LOADER_DURATION } from "../../../helpers/Constants";
// import GlobalEnums from "../../../helpers/GlobalEnums";
// import "./ProductBoxHome.css"

// const ProductBoxHome = ({ item, hoverEffect, layout, ProductDetailPageForceUpload }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
//     const [imgsrc, setImgsrc] = useState("");

//     const [compare, addCompare] = useState("");
//     const [wishList, addWish] = useState("");
//     const [onSale, setOnSale] = useState(item.DiscountedPrice != undefined && item.DiscountedPrice != null && item.DiscountedPrice > 0);
//     const customerCompareList = useSelector(state => state.cartReducer.customerCompareList);
//     const [langCode, setLangCode] = useState('');
//     const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);


//     const imgChange = (src) => {
//         setImgsrc(src);
//     };


//     const reloadProductDetail = (_productId, _categoryName, _productName) => {

//         let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${replaceWhiteSpacesWithDashSymbolInUrl(_categoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(_productName)}`

//         if (ProductDetailPageForceUpload != undefined && ProductDetailPageForceUpload != null && ProductDetailPageForceUpload == true && _productId != undefined) {
//             navigate(productDetailUrlFromForceReload, { replace: true });
//             window.location.reload();
//         }
//     }

//     const HandleCustomerWishList = (ProductID, ProductName, Price, DiscountedPrice, DiscountId, IsDiscountCalculated, CouponCode, defaultImage) => {


//         let customerWishList = AddCustomerWishList(ProductID, ProductName, Price, DiscountedPrice, DiscountId, IsDiscountCalculated, CouponCode, 0, '', 0, '', 1, defaultImage);

//         //--store in storage
//         localStorage.setItem("customerWishList", customerWishList);
//         dispatch(rootAction.cartAction.setCustomerWishList(customerWishList));

//     }

//     const handleCompareList = (ProductId) => {



//         try {



//             //--check if product already exists
//             if (customerCompareList == undefined || customerCompareList?.filter(obj => obj.ProductId == ProductId).length == 0) {
//                 let customerCompareLocal = [];
//                 customerCompareLocal = customerCompareList == undefined ? [] : customerCompareList;
//                 customerCompareLocal.push({
//                     ProductId: ProductId

//                 });

//                 console.log(customerCompareLocal);
//                 dispatch(rootAction.cartAction.setCustomerCompareList(customerCompareLocal));
//             }

//             navigate('/' + getLanguageCodeFromSession() + '/compare');
//         }
//         catch (err) {
//             console.log(err);
//             showErrorMsg("An error occured. Please try again!");

//         }


//     }

//     useEffect(() => {
//         // declare the data fetching function
//         const dataOperationInUseEffect = async () => {

//             //--Get language code
//             let lnCode = getLanguageCodeFromSession();
//             await setLangCode(lnCode);

//             //-- Get website localization data
//             let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["ProductBox_1"], null);
//             if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
//                 await setLocalizationLabelsArray(arryRespLocalization);
//             }



//         }

//         //--start loader
//         dispatch(rootAction.commonAction.setLoading(true));

//         // call the function
//         dataOperationInUseEffect().catch(console.error);

//         //--stop loader
//         setTimeout(() => {
//             dispatch(rootAction.commonAction.setLoading(false));
//         }, LOADER_DURATION);

//     }, [])

//     // Changes in new 

//     const popoverRef = useRef(null);
//     useEffect(() => {
//         const popoverElement = popoverRef.current;
//         if (popoverElement) {
//             const popover = new window.bootstrap.Popover(popoverElement, {
//                 placement: 'top',
//                 content: 'share',
//                 trigger: 'hover focus',
//                 container: 'body' // Optional: this places the popover in the body
//             });

//             return () => {
//                 popover.dispose();
//             };
//         }
//     }, []);


//     return (
//         <Fragment>
//             <div className="product-box">

//                 <div className="product-imgbox">
//                     <div className="product-front">
//                         {/* <Img src={myImage} className="img-fluid" alt="product" /> */}

//                         {(() => {

//                             let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`;
//                             return (
//                                 <>
//                                     <Link to={urlViewDetailImage} onClick={() => reloadProductDetail(item.ProductId, item.CategoryName, item.ProductName)}>

//                                         {
//                                             item?.ProductImagesJson?.slice(0, 1)?.map((img, imgIdx) =>
//                                                 <>

//                                                     <Img src={adminPanelBaseURL + img.AttachmentURL} className="img-fluid" alt="product" />
//                                                 </>

//                                             )
//                                         }

//                                     </Link>
//                                 </>
//                             );
//                         })()}



//                     </div>



//                     {item?.MarkAsNew && (
//                         <div className="new-label1">
//                             <div>
//                                 {LocalizationLabelsArray.length > 0 ?
//                                     replaceLoclizationLabel(LocalizationLabelsArray, "new", "lbl_productbox_1_new")
//                                     :
//                                     "new"
//                                 }
//                             </div>
//                         </div>
//                     )}
//                     {onSale && <div className="on-sale1">

//                         {LocalizationLabelsArray.length > 0 ?
//                             replaceLoclizationLabel(LocalizationLabelsArray, "on sale", "lbl_productbox_1_onsale")
//                             :
//                             "on sale"
//                         }
//                     </div>}
//                 </div>

//                 <div className="product-detail detail-inline ">
//                     <div className="detail-title">
//                         <div className="detail-left">

//                             <ProductRatingStars Rating={item.Rating == undefined || item.Rating == null ? 5 : item.Rating} />
//                             <Link to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}>See More</Link>

//                             {/* {layout === "list-view" ? (
//                                 <p>
//                                     {makeProductShortDescription(item.ShortDescription, 45)}
//                                 </p>
//                             ) : (
//                                 ""
//                             )}
//                             <Link to={`/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}>
//                                 <h6 className="price-title">{makeProductShortDescription(item.ProductName, 50)}</h6>
//                             </Link> */}

//                         </div>
//                         <div className="detail-right">
//                             {/* <span class="material-symbols-outlined">ios_share</span> */}

//                             <div
//                                 ref={popoverRef}


//                             >
//                                 <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
//                                     <li

//                                         style={{ cursor: "pointer" }}
//                                     >
//                                         {/* <img src={require("../../../resources/custom/images/reels icons.png")} width={45} alt="popover-trigger" /> */}
//                                         {/* <span class="material-symbols-outlined">ios_share</span> */}
//                                         <div>
//                                             <span className="" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas">
//                                                 <span className="material-symbols-outlined">ios_share</span>
//                                             </span>

//                                             <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasRightLabel">
//                                                 <div className="offcanvas-header">
//                                                     <h5 className="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5>
//                                                     <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//                                                 </div>
//                                                 <div className="offcanvas-body">
//                                                     {/* Content goes here */}
//                                                 </div>
//                                             </div>
//                                         </div>

//                                     </li>
//                                 </ul>
//                             </div>



//                             {/* 
//                             {item.DiscountedPrice != undefined && item.DiscountedPrice > 0 ?
//                                 <div className="check-price">
//                                     {GetDefaultCurrencySymbol()}{item.DiscountedPrice}
//                                     {" "}
//                                 </div>
//                                 :
//                                 <span className="dis-empty-value">
//                                     {'\u00A0'}{'\u00A0'}
//                                 </span>
//                             }

//                             <div className="price">
//                                 <div className="price">

//                                     {GetDefaultCurrencySymbol()}{item.Price}
//                                 </div>
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>

//             </div>

//         </Fragment>
//     );
// };
// export default ProductBoxHome;


import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Img from "../../../helpers/utils/BgImgRatio";
import { replaceWhiteSpacesWithDashSymbolInUrl } from "../../../helpers/ConversionHelper";
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import ProductRatingStars from "./ProductRatingStars";
import rootAction from "../../../stateManagment/actions/rootAction";
import { showErrorMsg } from "../../../helpers/ValidationHelper";
import { LOADER_DURATION } from "../../../helpers/Constants";
import GlobalEnums from "../../../helpers/GlobalEnums";
import "./ProductBoxHome.css";
import email from"../../../resources/custom/images/Pinterest-logo.png"
import fac from"../../../resources/custom/images/fac.png"
import twitter from"../../../resources/custom/images/twitter.png"

const ProductBoxHome = ({ item, ProductDetailPageForceUpload }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [adminPanelBaseURL, setBaseUrl] = useState(Config['ADMIN_BASE_URL']);
    const [onSale, setOnSale] = useState(item.DiscountedPrice != undefined && item.DiscountedPrice != null && item.DiscountedPrice > 0);
    const customerCompareList = useSelector(state => state.cartReducer.customerCompareList);
    const [langCode, setLangCode] = useState('');
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const popoverRef = useRef(null);
    const [copyurl, setCopyUrl] = useState(false)

    useEffect(() => {
        const dataOperationInUseEffect = async () => {
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["ProductBox_1"], null);
            if (arryRespLocalization && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        };

        dispatch(rootAction.commonAction.setLoading(true));
        dataOperationInUseEffect().catch(console.error);
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
    }, [dispatch]);

    const togglePopover = () => {
        setShowPopover(!showPopover);
    };

    const reloadProductDetail = (_productId, _categoryName, _productName) => {
        let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${replaceWhiteSpacesWithDashSymbolInUrl(_categoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(_productName)}`;
        if (ProductDetailPageForceUpload && _productId) {
            navigate(productDetailUrlFromForceReload, { replace: true });
            window.location.reload();
        }
    };

    const handleCustomerWishList = (ProductID, ProductName, Price, DiscountedPrice, DiscountId, IsDiscountCalculated, CouponCode, defaultImage) => {
        // Implement wish list logic here
    };

    const handleCompareList = (ProductId) => {
        try {
            if (!customerCompareList || !customerCompareList.find(obj => obj.ProductId === ProductId)) {
                let customerCompareLocal = [...(customerCompareList || []), { ProductId }];
                dispatch(rootAction.cartAction.setCustomerCompareList(customerCompareLocal));
            }
            navigate('/' + getLanguageCodeFromSession() + '/compare');
        } catch (err) {
            console.log(err);
            showErrorMsg("An error occurred. Please try again!");
        }
    };

    return (
        <Fragment>
            <div className="product-box">
                <div className="product-imgbox">
                    <div className="product-front">
                        {(() => {
                            let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`;
                            return (
                                <Link to={urlViewDetailImage} onClick={() => reloadProductDetail(item.ProductId, item.CategoryName, item.ProductName)}>
                                    {item?.ProductImagesJson?.slice(0, 1)?.map((img, imgIdx) =>
                                        <Img key={imgIdx} src={adminPanelBaseURL + img.AttachmentURL} className="img-fluid" alt="product" />
                                    )}
                                </Link>
                            );
                        })()}
                    </div>

                    {item?.MarkAsNew && (
                        <div className="new-label1">
                            <div>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, "new", "lbl_productbox_1_new")
                                    :
                                    "new"
                                }
                            </div>
                        </div>
                    )}
                    {onSale && <div className="on-sale1">
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "on sale", "lbl_productbox_1_onsale")
                            :
                            "on sale"
                        }
                    </div>}
                </div>

                <div className="product-detail detail-inline">
                    <div className="detail-title">
                        <div className="detail-left">
                            <ProductRatingStars Rating={item.Rating || 5} />
                            <Link to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}>See More...</Link>
                        </div>
                        <div className="detail-right">
                            <div className="shareBox">
                                <span className="material-icons" onClick={togglePopover}><span class="material-symbols-outlined ">ios_share</span></span>
                                <div className="boxshare">
                                    <span>share</span>
                                </div>
                            </div>
                            {showPopover && (
                                <div ref={popoverRef} className="popover ">
                                    <header>
                                        <button className="close-button" onClick={togglePopover} aria-label="Close Share Popup">
                                            <span class="material-symbols-outlined">close</span>
                                        </button>
                                        <span class="material-symbols-outlined">share</span>
                                    </header>
                                    <div className="popover-body ">
                                        <ul className="share-options">
                                            <li>
                                                <a className="share-option email" href="mailto:info@parijathandicraft.com" onClick={togglePopover}>
                                                    <span class="material-symbols-outlined text-dark me-2">mail</span>
                                                    <span className="label">Email</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="share-option pinterest" href="https://in.pinterest.com/handicraftparijat/." onClick={togglePopover}>
                                                    <span className="icon" style={{ backgroundImage: `url(${email})` }}></span>
                                                    <span className="label">Pinterest</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="share-option facebook" href="https://www.facebook.com/prijathandicraft/" onClick={togglePopover}>
                                                    <span className="icon" style={{ backgroundImage: `url(${fac})` }}></span>
                                                    <span className="label">Facebook</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="share-option twitter" href="https://x.com/handicraftpari1" onClick={togglePopover}>
                                                <span className="icon" style={{ backgroundImage: `url(${twitter})` }}></span>
                                                <span className="label">X</span>
                                            </a>
                                            </li>
                                            <li>
                                                {(() => {
                                                    // Construct and encode the URL
                                                    let baseURL = `/${getLanguageCodeFromSession()}/product-detail/${item.ProductId}/${replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`;
                                                    let urlViewDetailImage = `http://localhost:3000${baseURL}`; // Make sure to include full base URL

                                                    // Function to copy URL
                                                    const handleCopy = async () => {
                                                        try {

                                                            await navigator.clipboard.writeText(urlViewDetailImage);
                                                            setCopyUrl(true)
                                                            setTimeout(() => {
                                                                setCopyUrl(false)
                                                                setShowPopover(false)

                                                            }, 1500)
                                                            // alert('Link copied to clipboard');
                                                        } catch (err) {
                                                            console.error('Failed to copy: ', err);
                                                            alert('Failed to copy link. Please try again.');
                                                        }
                                                    };

                                                    return (
                                                        <a className="share-option copy" href="#" onClick={(e) => { e.preventDefault(); handleCopy(); }}>
                                                            <span className="icon" style={{ backgroundImage: 'url(https://m.media-amazon.com/images/G/01/share-icons/link-circular.svg)' }}></span>
                                                            <span className="label">{copyurl ? <span style={{ color: "green" }}>Link Copied!</span> : "Copy Link"}</span>
                                                        </a>
                                                    );
                                                })()}
                                            </li>




                                        </ul>
                                    </div>
                                    <div className="arrow"></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductBoxHome;





